<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('leaveRecord.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('leaveRecord.pageTitle'),
                        href: '/admin/leave-records'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-3">
                        <profile
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                        />
                    </div>
                    <div class="col-md-9 tw-pb-5">
                        <ts-panel>
                            <ts-panel-wrapper class="tw-flex">
                                <detail
                                    ref="detail"
                                    v-model="model"
                                    :validate="errors"
                                    class="tw-h-full"
                                />
                                <div
                                    class="tw-w-full tw-h-full tw-m-auto "
                                    v-if="model.total_day != null"
                                >
                                    <label
                                        class="col-md-5 form-col-label control-label tw-text-right tw-p-2 tw-font-bold tw-w-full"
                                        >Total:
                                        {{
                                            model.total_day > 1
                                                ? model.total_day + $t("days")
                                                : model.total_day + $t("day")
                                        }}
                                    </label>
                                </div>
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
            </div>
        </vue-custom-scrollbar>
        <Spin size="large" fix v-if="loading"> </Spin>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'leave-record' })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                :waiting="waiting_new"
                :disabled="waiting_new || waiting"
                @click.prevent="onSaveAddNew"
            >
                {{ $t("saveAddNew") }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new || waiting"
                @click.prevent="onSave"
            >
                {{ $t("save") }}</ts-button
            >
        </div>
    </div>
</template>
<script>
import AppOptions from "@/config/AppOptions.vue";
import { Errors } from "form-backend-validation";
import Detail from "./components/detail.vue";
import Profile from "./components/profile.vue";
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";
import moment from "moment";

export default {
    name: "leave-recordCreate",
    components: { Detail, Profile },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            visible: false,
            model: {
                leave_type_id: null,
                employee_id: null,
                date_from: moment(new Date()).format("DD-MM-YYYY"),
                date_to: moment(new Date()).format("DD-MM-YYYY"),
                time_from: null,
                time_to: null,
                leave_reason: null,
                total_day: null,
                leave_sub_item_id: null,
                approve_status: 2,
                current_approve_level: 2,
                is_allow_sub_item: false
            }
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    computed: {
        ...mapState("humanResource/leaveRecord", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    methods: {
        ...mapActions("humanResource/leaveRecord", [
            "getEmployee",
            "getLeaveType",
            "getLeaveTypeSubItem"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getEmployee();
            await this.getLeaveType();
            await this.getLeaveTypeSubItem();
            this.$refs.detail.clearTimeFromTo();
            this.loading = false;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/leaveRecord/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/leaveRecord/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                    this.$router.push({ name: "leave-record" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            this.model.leave_type_id = null;
            this.model.employee_id = null;
            this.model.date_from = null;
            this.model.date_to = null;
            this.model.time_from = null;
            this.model.time_to = null;
            this.model.leave_reason = null;
            this.model.total_day = null;
            this.model.leave_sub_item_id = null;
            this.model.is_allow_sub_item = false;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE RECORD",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
